define("discourse/plugins/framework-sentry/discourse/initializers/framework-sentry", ["exports", "discourse/lib/plugin-api"], function (_exports, _pluginApi) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function initializePlugin(api) {
    const enabled = Discourse.SiteSettings.framework_settings_enabled && Discourse.SiteSettings.framework_sentry_enabled;
    const dsn = Discourse.SiteSettings.framework_sentry_dsn;
    const version = Discourse.SiteSettings.framework_sentry_js_version;
    if (!enabled || !dsn || !version) {
      return;
    }
    const loaderScript = document.createElement('script');
    loaderScript.crossorigin = 'anonymous';
    loaderScript.src = `https://browser.sentry-cdn.com/${version}/bundle.min.js`;
    loaderScript.onload = () => {
      window.Sentry.init({
        dsn: dsn,
        environment: Discourse.SiteSettings.framework_environment,
        release: Discourse.SiteSettings.framework_release
      });
      const currentUser = api.getCurrentUser();
      if (currentUser) {
        const {
          id,
          username
        } = currentUser;
        window.Sentry.configureScope(scope => {
          scope.setUser({
            id,
            username
          });
        });
      }
    };
    document.head.appendChild(loaderScript);
  }
  ;
  var _default = _exports.default = {
    name: 'framework-sentry',
    initialize() {
      (0, _pluginApi.withPluginApi)('0.11', api => initializePlugin(api));
    }
  };
});